import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    AutocompleteInput,
    DateTimeInput,
    FileInput,
    FileField,
    BooleanInput,
    ReferenceInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForRessource';
import dateUtils from '../utils/dateUtils';
import utils from '../utils/utils';

class CustomTableEdit extends React.Component {
    state = { data: null }

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource} {record.number ? `${record.number}` : ``}</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    customHandled(fields, fieldName, testing) {
        return false
    }
    

    generateFromFields(fields) {
        let that = this

        var testing = []
        console.log('PASSED HERE')
        console.log(fields)
        for (const fieldName in fields) {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            if (!this.customHandled(fields, fieldName, testing)) {

                if (fields[fieldName].type === 'String' && fields[fieldName].enum !== undefined) {
                    testing.push(<SelectInput source={fieldName} label={fieldLabel} choices={this.transformInChoices(fields[fieldName].enum)} />)
                } else if (fields[fieldName].type === 'String') {
                    testing.push(<TextInput source={fieldName} label={fieldLabel}/>)
                } else if (fields[fieldName].type === 'Number') {
                    testing.push(<NumberInput source={fieldName} label={fieldLabel}/>)
                } else if (fields[fieldName].type === 'Date') {
                    // testing.push(<DateInput source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                    // testing.push(<DateInput source={fieldName} label={fieldLabel} parse={dateUtils.dateParser} />)
                    testing.push(<DateTimeInput source={fieldName} label={fieldLabel} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                } else if (fields[fieldName].type === 'Boolean') {
                    testing.push(<BooleanInput source={fieldName} label={fieldLabel}/>)
                } else if (fields[fieldName].type === 'Object') {
                    fieldLabel +=  ' (max: 20Mb)';
                    testing.push(<FileInput source={fieldName} label={fieldLabel}
                    // accept="application/vnd.android.package-archive application/zip">
                    // accept="application/*">
                    >
                            <FileField source="src" title="title" />
                        </FileInput>)
                } else if (fields[fieldName].type === 'ObjectId') {
                    const plural = utils.getPlural(fields[fieldName].ref)
                    testing.push(<ReferenceInput source={fieldName} label={fieldLabel} reference={plural} allowEmpty>
                        <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
                    </ReferenceInput>)
                } else if (Array.isArray(fields[fieldName].type)) {

                    console.log(fields[fieldName].type)

                    var subObject = that.generateFromFields(fields[fieldName].type[0])
                    
                    testing.push(<ArrayInput source={fieldName} label={fieldLabel}> 
                        <SimpleFormIterator>
                            {subObject}
                        </SimpleFormIterator>
                    </ArrayInput>)


                    // testing.push(<ReferenceArrayInput label={fieldName} source={fieldName} reference={fieldName} allowEmpty>
                    //         <SelectArrayInput optionText="name" />
                    // </ReferenceArrayInput>)

                }
            }
        };
        return testing
    }

    componentDidMount() {
        let that = this

        return getFieldsForRessource(this.props.resource).then((fields) => {

            var testing = that.generateFromFields(fields)

            this.setState({
                data: <Edit {...this.props} title={<this.ShowTitle />}>
                <SimpleForm>
                    {testing}
                </SimpleForm>
            </Edit>
            });
        });
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }

    transformInChoices(base) {
        let returnObject = []
        for (const obj in base) {
            returnObject.push({
                "id": base[obj],
                "name": base[obj]
            })
        }
        return returnObject;
    }
    
}

export default CustomTableEdit;
