import React from 'react';
import decodeJwt from 'jwt-decode';
import qs from 'qs';

class AuthResponsePage extends React.Component {

    state = { data: null }

    componentDidMount() {

        const code = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code;
        console.log(`code: ${code}`);

        if (code) {

            const request = new Request(`${process.env.REACT_APP_API_URL}/sso/login`, {
                method: 'POST',
                body: JSON.stringify({ code }),
                headers: new Headers({ 'Content-Type': 'application/json' })
            });
            fetch(request).then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                response.json().then((respJson) => {
                    const token = respJson.token;
                    const decodedToken = decodeJwt(token);
                    localStorage.setItem('token', token);
                    localStorage.setItem('permissions', decodedToken.permissions);

                    this.props.history.push('/');
                });
            });
  
        }
    }

    render() {
        return (
            <div>
                <p>Redirecting...</p>
            </div>
        );
    }
}

export default AuthResponsePage;