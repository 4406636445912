// in src/contracts.js
import * as React from 'react';
import { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import {
    TabbedShowLayout,
    Tab,
    DateField,
    Datagrid,
    DateInput,
    Filter,
    List,
    ReferenceInput,
    RichTextField,
    SearchInput,
    Show,
    SimpleShowLayout,
    TextField,
    useListContext,
    TopToolbar,
    ExportButton,
    sanitizeListRestProps,
    Pagination
} from 'react-admin';


const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button'
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};

const ContractFilter = (props) =>
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput source="startingDate" reference="contracts">
            <DateInput />
        </ReferenceInput>
        <ReferenceInput source="endDate" reference="contracts">
            <DateInput />
        </ReferenceInput>
        {/* <ReferenceInput source="partyAinfo" reference="contracts">
					<SelectInput  optionText="Company name" />
			</ReferenceInput> */}
    </Filter>;
const PostShow = (props) => {

    const collapsedView = [];

    collapsedView.push(<TextField source="project"/>);

    collapsedView.push(<TextField label="Party B Signatory name" source="partyBsignature.name"/>);
    collapsedView.push(<TextField label="Party B Signatory position" source="partyBsignature.position"/>);
    collapsedView.push(<TextField label="Party B Signatory email" source="partyBsignature.email"/>);

    collapsedView.push(<TextField label="Party A Signatory name" source="partyAsignature.name"/>);
    collapsedView.push(<TextField label="Party A Signatory position" source="partyAsignature.position"/>);
    collapsedView.push(<TextField label="Party A Signatory email" source="partyAsignature.email"/>);

    collapsedView.push(<TextField label="Party C Signatory name" source="partyCsignature.name"/>);
    collapsedView.push(<TextField label="Party C Signatory position" source="partyCsignature.position"/>);
    collapsedView.push(<TextField label="Party C Signatory email" source="partyCsignature.email"/>);

    return <Show
        {...props}
        /* disable the app title change when shown */
        title=" " >
        <SimpleShowLayout>

            {collapsedView}

        </SimpleShowLayout>
    </Show>;
};

const PostPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />;

export const ContractList = (props) =>
    <List {...props} filters={<ContractFilter />} actions={<ListActions />} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} pagination={<PostPagination />}>
        <Datagrid expand={< PostShow />} optimized>

            <TextField label="Party B Company" source="partyBinfo.company" />
            <TextField label="Party B Name" source="partyBinfo.name" />

            <TextField label="Party A Company" source="partyAinfo.company" />
            <TextField label="Party A Name" source="partyAinfo.name" />

            <TextField label="Status" source="status" />

        </Datagrid>
    </List>;
const classes = makeStyles({
    root: { width: '50%', margin: 'auto' },
    spacer: { height: 20 },
    invoices: { margin: '10px 0' }
});


export const ContractShow = (props) => {
    console.log('ContractShow');
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="General Info">
                    <SimpleShowLayout className={classes.root}>
                        <h3>Party A</h3>
                        <TextField label="Company" source="partyAinfo.company" />
                        <TextField label="Company Type" source="partyAinfo.companyType" />
                        <TextField label="Country" source="partyAinfo.country" />
                        <TextField label="Name" source="partyAinfo.name" />
                        <TextField label="Position" source="partyAinfo.position" />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Project Info">
                    <SimpleShowLayout>
                        <h3>Project General Info</h3>
                        <RichTextField label="Project" source="project" />
                        <DateField label="Starting date" source="startingDate" />
                        <DateField label="End date" source="endDate" />
                        <TextField label="Duration (year)" source="durationProject" />
                        <TextField label="Days prior notice" source="daysPriorNotice" />
                        <RichTextField label="Information Included" source="informationIncluded" />
                        <TextField label="Days after declaration Dispute" source="daysAfterDeclarationDispute" />
                        <TextField label="Court/Tribunal Jurisdiction" source="courtJurisdiction" />
                        <TextField label="Applicable Law" source="applicableLaw" />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Contact Info">
                    <SimpleShowLayout>
                        <h3>Party A</h3>
                        <TextField label="Name" source="partyAcontact.name" />
                        <TextField label="Mail" source="partyAcontact.email" />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Signature Info">
                    <SimpleShowLayout>
                        <h3>Party A</h3>
                        <TextField label="Name" source="partyAsignature.name" />
                        <TextField label="Position" source="partyAsignature.position" />
                        <TextField label="Mail" source="partyAsignature.email" />
                        <TextField label="Phone" source="partyAsignature.phone" />
                    </SimpleShowLayout>
                </Tab>
            </TabbedShowLayout>
        </Show>);
};