import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import ContractIcon from '@material-ui/icons/Description';
import CountryIcon from '@material-ui/icons/Public';
import SignatoryIcon from '@material-ui/icons/Person';
import CompanyIcon from '@material-ui/icons/Business';
import JursidictionIcon from '@material-ui/icons/Gavel';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import MyLoginPage from './pages/MyLoginPage';

import './App.css';

import { ContractList, ContractShow } from './contracts';

import SophiaGeneticsTheme from './SophiaGeneticsTheme';

import CustomTableCreate from './creates/CustomTableCreate';
import CustomTableEdit from './edits/CustomTableEdit';
import CustomTableList from './lists/CustomTableList';

import CompanyCreate from './creates/CompanyCreate';
import CompanyEdit from './edits/CompanyEdit';
import CompanyList from './lists/CompanyList';

import AuthResponsePage from './pages/AuthResponsePage';

import createHistory from 'history/createBrowserHistory';

const history = createHistory();

class App extends React.Component {

    render() {
        return <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            history={history}
            loginPage={MyLoginPage}
            theme={SophiaGeneticsTheme}
            customRoutes={[
                // <RouteWithoutLayout
                <Route noLayout
                    path="/auth-response"
                    component={AuthResponsePage}
                />
            ]}
        >


            <Resource
                name="contracts"
                show={ContractShow}
                list={ContractList}
                icon={ContractIcon}
            />

            {/* <Resource
                name={'countries'}
                create={CustomTableCreate}
                list={CustomTableList}
                edit={CustomTableEdit}
                // show={CustomTableShow}
                icon={CountryIcon}
            /> */}

            <Resource
                name={'signatories'}
                create={CustomTableCreate}
                list={CustomTableList}
                edit={CustomTableEdit}
                // show={CustomTableShow}
                icon={SignatoryIcon}
            />

            <Resource
                name={'companies'}
                create={CompanyCreate}
                list={CompanyList}
                edit={CompanyEdit}
                // show={CustomTableShow}
                icon={CompanyIcon}
            />

            <Resource
                name={'jurisdictions'}
                create={CustomTableCreate}
                list={CustomTableList}
                edit={CustomTableEdit}
                icon={JursidictionIcon}
            />

        </Admin>;
    }
}

require('dotenv').config();


export default App;