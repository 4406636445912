import React from 'react';
import {
    Login,
    LoginForm
} from 'react-admin';

const MyLoginPage = (props) => {

    console.log('props');
    console.log(props);
    const loginForm = <>
        <LoginForm />
        <a href="https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=b0fe5935-6716-40fb-a2e1-fc7aae278b70&scope=openid+email&response_mode=query&response_type=code&redirect_uri=https%3A%2F%2Fsophia-genetics-admin.dev.mediasia.cn%2Fauth-response" class="sso-link">Login with Azure</a>
    </>;
    return <Login
        children={loginForm}

    />;
};

export default MyLoginPage;