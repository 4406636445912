import React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

import utils from '../utils/utils';


class CompanyCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'signatories') {
            const plural = utils.getPlural(fields[fieldName].type[0].ref);
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);

            fieldList.push(<ReferenceArrayInput label={fieldLabel} source={fieldName} reference={plural} allowEmpty>
                <AutocompleteArrayInput optionText="name" source="name" />
            </ReferenceArrayInput>);
            return true;
        } else if (fieldName === 'jurisdictions') {
            const plural = utils.getPlural(fields[fieldName].type[0].ref);
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);

            fieldList.push(<ReferenceArrayInput label={fieldLabel} source={fieldName} reference={plural} allowEmpty>
                <AutocompleteArrayInput optionText="law" source="law"/>
            </ReferenceArrayInput>);
            return true;
        }
        return false;
    }
}

export default CompanyCreate;