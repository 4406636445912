import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    NumberField,
    DateField,
    BooleanField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForRessource';

class CustomTableList extends React.Component {
    state = { data: null }
    myFilter = (props) => (
        <Filter {...props}>
        </Filter>
    );

    ShowTitle = ({ record }) => {
        return <span>{this.props.resource.charAt(0).toUpperCase() + this.props.resource.slice(1)}</span>;
        // return <span>{this.props.resource} {record.number ? `${record.number}` : `${record.id}`}</span>;
    };

    canExport = false

    lastCheckBeforeValidate(data) {
        return new Promise((resolve, reject) => {
            let datagrid = null
            if (this.PostShow()) {
                datagrid = <Datagrid rowClick="show" expand={< this.PostShow />}>
                        {data}
                    </Datagrid>
            } else {
                datagrid = <Datagrid rowClick="show">
                        {data}
                    </Datagrid>
            }

            if (this.customActions()) {
                resolve({
                    data: <List {...this.props} bulkActionButtons={false} exporter={this.canExport} title={<this.ShowTitle />} actions={< this.customActions />}
                    filters={< this.myFilter />} filterDefaultValues={this.filterDefaultValues()}>
                        {datagrid}
                    </List>
                });
            } else {
                resolve({
                    data: <List {...this.props} bulkActionButtons={false} exporter={this.canExport} title={<this.ShowTitle />}
                    filters={< this.myFilter />} filterDefaultValues={this.filterDefaultValues()}>
                        {datagrid}
                    </List>
                });
            }
          });
    }

    customHandled(fields, fieldName, testing) {
        return false
    }

    componentDidMount() {
        return getFieldsForRessource(this.props.resource).then((fields) => {
        
            var testing = []
            for (const fieldName in fields) {
                if (!this.customHandled(fields, fieldName, testing)) {

                    if (fields[fieldName].type === 'String') {
                        testing.push(<TextField source={fieldName}/>)
                    } else if (fields[fieldName].type === 'Number') {
                        testing.push(<NumberField source={fieldName}/>)
                    } else if (fields[fieldName].type === 'Date') {
                        testing.push(<DateField source={fieldName} showTime />)
                    } else if (fields[fieldName].type === 'Boolean') {
                        testing.push(<BooleanField source={fieldName} />)
                    } else if (fields[fieldName].type === 'ObjectId') {
                        const plural = fields[fieldName].ref + 's';
                        testing.push(<ReferenceField source={fieldName} reference={plural} link="show">
                            <TextField source="name" />
                        </ReferenceField>)
                    } else if (Array.isArray(fields[fieldName].type)) {
                        testing.push(<ReferenceArrayField source={fieldName} reference={fieldName}>
                            <SingleFieldList>
                                {/* <ChipField source="name" /> */}
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>)
                    }
                }

            };

            this.lastCheckBeforeValidate(testing).then((data) => {
                this.setState(data);
            })
        });
    }


    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }

    PostShow(props){
        return null
    }

    customActions(props) {
        return null
    }

    filterDefaultValues(props) {
        return {};
    }
}

export default CustomTableList;
