import React from 'react';
import {
    ReferenceArrayField,
    SingleFieldList,
    ChipField
} from 'react-admin';
import CustomTableList from './CustomTableList';

import utils from '../utils/utils';

class CountryList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'signatories') {
            const displayField = 'name';
            const ref = utils.getPlural(fields[fieldName].type[0].ref);

            fieldList.push(<ReferenceArrayField source={fieldName} reference={ref}>
                <SingleFieldList>
                    <ChipField source={displayField} />
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;
        } else if (fieldName === 'jurisdictions') {
            const displayField = 'law';
            const ref = utils.getPlural(fields[fieldName].type[0].ref);

            fieldList.push(<ReferenceArrayField source={fieldName} reference={ref}>
                <SingleFieldList>
                    <ChipField source={displayField} />
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;
        }
        return false;
    }
}

export default CountryList;